<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h3 class="text-center">Openstaande inkooporders</h3>
    <Loading v-if="loading" />
    <div v-else>
      <Tabs>
        <Tab title="Betaalde inkooporders">
          <OpenPurchaseOrdersTable :table_data="open_purchase_orders_payed" :table_style="bu" name="Payed" />
        </Tab>
        <Tab
          v-for="(buyer, key) in open_purchase_orders"
          :key="key"
          :title="key"
        >
          <OpenPurchaseOrdersTable :table_data="buyer" :table_style="bu" :name="key" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import OpenPurchaseOrdersTable from "@/components/OpenPurchaseOrdersTable.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["bu"],
  components: {
    Loading,
    OpenPurchaseOrdersTable,
    Tab,
    Tabs
  },
  data: () => ({
    loading: true,
    open_purchase_orders: null,
    open_purchase_orders_payed: null
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      this.loading = true;
      request(`open-purchase-orders/${bu}`, "GET").then(
        ({ open_purchase_orders }) => {
          this.open_purchase_orders = open_purchase_orders;
          this.loading = false;
        }
      );

      request(`open-purchase-orders-payed/${bu}`, "GET").then(
        ({ open_purchase_orders_payed }) => {
          this.open_purchase_orders_payed = open_purchase_orders_payed;
          this.loading = false;
        }
      );
    },
  },
};
</script>
